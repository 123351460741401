import React from 'react';
import classnames from 'classnames';
import Proptypes from 'prop-types';
import { Grid, Typography, Button, Hidden } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { Link, EmojiSpan, getStylesBase, menuHeightOffset } from '../../../styledComponents';
import ScrollArrow from '../../../ui/ScrollArrow/component';
import heroImage from '../../../../assets/home/hero.jpg';
import { getPageUrl } from '../../../../Routes';

const Hero = ({ classes }) => {
  const scrollRef = React.createRef();

  return (
    <>
      <Grid container className={classes.mainContainer}>
        <Grid className={classnames(classes.muiGridFullScreen, classes.imageContainer)}>
          <img className={classes.image} src={heroImage} alt="Ovio" />
        </Grid>
        <Grid
          container
          item
          className={classes.textContainer}
          direction="column"
          justify="center"
          xs={12}
        >
          <Typography className={classes.typography} variant="h1">
            Welcome
            {' '}
            <Typography variant="h2" component="span">
              <EmojiSpan label="games emoji">
                🎲🎮
              </EmojiSpan>
            </Typography>
          </Typography>
          <Grid className={classes.buttonContainer} item>
            <Link to={getPageUrl('Games')}>
              <Button className={classes.muiButtonWhiteBackground}>Browse to Games</Button>
            </Link>
          </Grid>
          <Hidden xsDown>
            <ScrollArrow scrollRef={scrollRef} />
          </Hidden>
        </Grid>
      </Grid>
      <div ref={scrollRef} />
    </>
  );
};


Hero.propTypes = {
  classes: Proptypes.object.isRequired,
};

const styles = (theme) => ({
  ...getStylesBase(['muiButtonWhiteBackground', 'muiGridFullScreen']),
  mainContainer: {
    height: `calc(100vh - ${menuHeightOffset}px)`,
  },
  imageContainer: {
    position: 'absolute',
    top: `${menuHeightOffset}px`,
    left: '0px',
    width: '100%',
    zIndex: '-1',
  },
  image: {
    width: '100%',
    height: `calc(100vh - ${menuHeightOffset}px)`,
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: { objectPosition: 'left' },
  },
  textContainer: {
    marginLeft: '0px',
    textAlign: 'center',
  },
  typography: {
    textAlign: 'center',
    marginLeft: '0px',
  },
  buttonContainer: {
    margin: '50px 0px 105px',
    [theme.breakpoints.down('xs')]: { margin: '35px 0px' },
  },
});

export default withStyles(styles)(Hero);
