import React from 'react';

import Hero from './Hero/component';

const Home = () => (
  <>
    <Hero />
  </>
);

export default Home;
