import React from 'react';

import Layout from '../Layout/component';
import Home from '../components/mains/Home/component';

const IndexPage = () => (
  <Layout routeSlug="HomePage">
    <Home />
  </Layout>
);

export default IndexPage;
